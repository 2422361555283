@import './variables';

@import './libs/animate';

@import './animations';
@import './cards';
@import './content';
@import './cookies';
@import './links';
@import './forms';
@import './labels';
@import './icons';
@import './navigation';
@import './tables';
@import './titles';
@import './usp-list';

html,
body,
#root {
    height: 100%;
    min-width: 320px;
}

body {
    background-color: $color-spring-wood;
    color: $color-heavy-metal;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    font-size: 14px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 150%;
    margin: 0;

    @media (min-width: $breakpoint-sm) {
        font-size: 16px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

p,
ul,
li {
    line-height: 175%;
}

p {
    margin-top: 0;
}

li {
    margin-bottom: 12px;
}

.text-center {
    text-align: center;
}

div.dashed-line {
    background: repeating-linear-gradient(
        90deg,
        #e5e5e5,
        #e5e5e5 5px,
        #ffffff 5px,
        #ffffff 10px
    );
    height: 2px;
    position: relative;
    width: 100%;

    &.variant-darker {
        background: repeating-linear-gradient(
            90deg,
            #ecd393,
            #ecd393 5px,
            #f9f6f1 5px,
            #f9f6f1 10px
        );
    }
}

.MuiDialogTitle-root,
.MuiDialogContent-root {
    div.dashed-line {
        margin-bottom: 24px;
        margin-left: -32px;
        margin-top: 24px;
        width: calc(100% + 64px);

        @media (max-width: $breakpoint-sm) {
            margin-left: -24px;
            width: calc(100% + 48px);
        }
    }
}

.MuiDialogTitle-root {
    div.dashed-line {
        margin-bottom: 0;
        width: calc(100% + 96px);
    }
}

ul.check {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 16px;

        svg {
            color: $color-heavy-metal;
            font-size: 1.5rem;
            margin-right: 12px;
            vertical-align: middle;
        }
    }
}

ul.payment-methods {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        background: $color-bone;
        border-radius: 10px;
        margin: 0 16px 16px;
        max-width: 230px;
        padding: 24px;
        text-align: left;

        span {
            display: block;
            margin-bottom: 8px;

            img {
                margin-right: 16px;
                vertical-align: middle;
                width: 32px;
            }
        }

        small {
            display: block;
            line-height: 150%;
            margin-top: 12px;
        }
    }
}

.date-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    position: relative;

    .date {
        background: $color-bone;
        border-radius: 5px;
        line-height: 1.25;
        margin-right: 16px;
        min-width: 65px;
        padding: 12px 14px;
        text-align: center;

        @media (min-width: 600px) {
            min-width: 75px;
            padding: 14px 24px;
        }

        strong {
            display: block;

            &.month {
                color: $color-cigar;
                font-size: 0.875rem;
                text-transform: uppercase;
            }

            &.day {
                font-size: 1.35rem;
            }
        }

        span {
            display: none;

            &.year {
                color: $color-warm-grey;
                font-size: 0.875rem;
            }
        }
    }

    small {
        margin-top: 16px;
        width: 100%;
    }

    .meta {
        strong,
        time {
            display: block;
        }
    }
}

.spacer {
    display: block;
    height: 24px;
}
