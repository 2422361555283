// colors
$color-armadillo: #423f34;
$color-black: #000;
$color-bone: #e7d7c5;
$color-chalky: #ecd393;
$color-cigar: #7b5933;
$color-equator: #e4c167;
$color-firebrick: #b42a1e;
$color-heavy-metal: #2d392c;
$color-medium-laurel: #6c8a6b;
$color-mercury: #e8e7e3;
$color-mikado: #332515;
$color-mischka: #cdd4da;
$color-norway: #a4b8a3;
$color-paris-white: #c0cec0;
$color-permanent-geranium-lake: #da3030;
$color-pot-pourri: #fae6e6;
$color-spring-wood: #f9f6f1;
$color-warm-grey: #968f86;
$color-white: #fff;


// breakpoints material ui
$breakpoint-xs: 0;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;
