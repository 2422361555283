.form-error {
    background-color: $color-permanent-geranium-lake;
    border-radius: 5px;
    color: $color-white;
    margin-bottom: 2rem;
    padding: 12px 16px;
}

.form-control {
    small.error {
        color: $color-permanent-geranium-lake;
        font-weight: bold;
        margin-top: 4px;
    }
}

.MuiAutocomplete-input {
    padding: 12px 15px !important;
}

.MuiFormLabel-root + .MuiTextField-root {
    margin-top: 1.5rem;
}

.MuiFormControl-root.form-control {
    // margin-bottom: 1.5rem;

    &.no-spacing {
        margin-bottom: 0;
    }

    .MuiFormControl-root.form-control {
        margin-bottom: 0;
    }

    .children {
        font-size: 0.875rem;
        line-height: 1.5;
        margin-top: 0.5rem;
    }
}

form {
    .MuiGrid-root {
        &:last-child {
            .MuiFormControl-root.form-control {
                // margin-bottom: 0;

                .MuiFormControlLabel-root {
                    margin-bottom: -8px;
                }
            }
        }
    }
}

.MuiAutocomplete-option span {
    margin-right: 10px;
}

// .MuiGrid-item {
//     > .form-control {
//         margin-bottom: 0;
//     }
// }
