.label {
    background: $color-mikado;
    border-radius: 5px;
    color: $color-spring-wood;
    display: inline-block;
    font-size: .75rem;
    line-height: 150%;
    padding: 8px 16px;
    position: relative;

    &.green {
        background: $color-paris-white;
        color: $color-heavy-metal;
    }

    &.red {
        background: $color-pot-pourri;
        color: $color-firebrick;
    }
}
