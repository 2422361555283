@import './variables';

.content-blue,
.content-green,
.content-yellow {
    background: $color-mischka;
    margin-top: 0 !important;
    padding: 72px 0 64px;
    position: relative;

    h2 {
        margin-top: 0 !important;
    }

    &::before,
    &::after {
        background-color: transparent;
        background-image: url('../assets/images/bg-blue-top.svg');
        background-repeat: no-repeat;
        background-size: 1200px;
        content: ' ';
        display: block;
        height: 32px;
        left: 0;
        position: absolute;
        right: 0;
        top: -32px;
        width: 100%;

        @media (min-width: 1200px) {
            background-size: 100%;
        }
    }

    &::after {
        background-image: url('../assets/images/bg-blue-bottom.svg');
        bottom: -28px;
        top: auto;
    }
}

.content-green {
    background: $color-norway;

    &::before {
        background-image: url('../assets/images/bg-green-top.svg');
    }

    &::after {
        background-image: url('../assets/images/bg-green-bottom.svg');
    }
}

.content-yellow {
    background: $color-equator;

    &::before {
        background-image: url('../assets/images/bg-yellow-top.svg');
    }

    &::after {
        background-image: url('../assets/images/bg-yellow-bottom.svg');
    }
}
