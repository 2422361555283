@import './variables';

h1,
h2,
h3,
h4,
h5 {
    color: $color-mikado;
    font-family: 'Nunito', 'Lato', "Helvetica Neue", sans-serif;
    font-size: 3.75rem;
    font-weight: 800;
    line-height: 1.15;
    margin: 0;
}

.MuiTypography-h1,
.MuiTypography-h2,
.MuiTypography-h3,
.MuiTypography-h4,
.MuiTypography-h5,
.MuiTypography-h6 {
    line-height: 1.15 !important;
}

.MuiTypography-h1,
h1.MuiTypography-h1,
h1 {
    font-size: 2.5rem;
    line-height: 1.15;
    margin-bottom: 3rem;

    &.page-title {
        margin-top: 2rem;
    }

    @media (min-width: $breakpoint-sm) {
        font-size: 3rem;
    }

    @media (min-width: $breakpoint-md) {
        font-size: 3.75rem;
    }
}

h2 {
    font-size: 3rem;

    &.title-h2 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        margin-top: 3.5rem;
    }
}

h3 {
    font-size: 1.5rem;

    &.title-h3 {
        font-size: 1.25rem;
        margin-bottom: 2rem;
        margin-top: 3.5rem;
    }
}
