.usp-list-container {
    margin-bottom: 104px !important;  // important needed to override grid style
    margin-top: 104px !important;
    text-align: center;

    &.disable-margin {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .usp-list-item {
        line-height: 150%;

        h3 {
            font-size: 1.5rem;
            line-height: 125%;
            margin: 2rem 0 .5rem;
        }
    }
}

ol.usp-numbered-list {
    counter-reset: numbered-list;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        border-bottom: 1px solid $color-bone;
        margin-bottom: 24px;
        padding-bottom: 24px;
        padding-left: 0;
        padding-top: 8px;
        position: relative;

        &::before {
            background: $color-chalky;
            border-radius: 10px;
            content: counter(numbered-list);
            counter-increment: numbered-list;
            display: inline-block;
            font-family: 'Nunito';
            font-weight: bold;
            left: 0;
            padding: 8px 14px;
            position: absolute;
            top: 0;
        }

        a,
        > div {
            @media (min-width: $breakpoint-sm) {
                padding-left: 72px;
            }
        }

        a {
            color: $color-mikado;
            display: block;
            padding-left: 56px;
            padding-right: 32px;
            text-decoration: none;
        }

        p {
            margin: 0;
        }

        strong {
            display: block;
            font-family: 'Nunito';
            margin-bottom: 16px;
        }

        button:not(.toggle) {
            margin-left: -8px;
            margin-top: 24px;
        }

        img {
            display: block;
            margin-top: 32px;

            @media (min-width: $breakpoint-sm) {
                display: none;
            }
        }

        button.toggle {
            position: absolute;
            right: 0;
            top: -5px;

            svg {
                transform: rotate(0deg);
                transition: transform .2s ease;
            }
        }

        &.open {
            button {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
