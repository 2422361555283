.fade-enter {
    opacity: .01;
    // transform: scale(1.1);
}

.fade-enter-active {
    opacity: 1;
    // transform: scale(1);
    transition: all 300ms;
}

.fade-exit {
    opacity: 1;
    // transform: scale(1);
}

.fade-exit-active {
    opacity: .01;
    // transform: scale(1.1);
    transition: all 300ms;
}
