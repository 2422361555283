@import './variables';

.card-overlay {
    background-color: rgba($color-mikado, 0.35);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    &.dark {
        background-color: rgba($color-mikado, 0.75);
    }
}

.default-card {
    border-radius: 10px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 8px;

    .MuiCardContent-root {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-weight: normal;
        justify-content: space-between;
        padding: 24px;

        .MuiAvatarGroup-root {
            justify-content: center;

            @media (min-width: $breakpoint-sm) {
                justify-content: start;
            }
        }

        > div {
            margin-bottom: 16px;
            text-align: center;

            @media (min-width: $breakpoint-sm) {
                text-align: left;
            }

            @media (min-width: $breakpoint-md) {
                margin-bottom: 0;
                text-align: left;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (min-width: $breakpoint-sm) {
            flex-direction: row;
            padding: 24px 32px;
        }

        @media (min-width: $breakpoint-md) {
        }

        .card-buttons {
            text-align: right;

            button {
                display: block;
                margin-bottom: 16px;
                width: 100%;

                @media (min-width: $breakpoint-md) {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-left: 8px;
                    width: auto;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @for $i from 1 through 100 {
        .card-w-#{$i * 1} {
            max-width: #{$i * 1%};
            width: #{$i * 1%};
        }
    }

    @media (min-width: $breakpoint-sm) {
        @for $i from 1 through 100 {
            .card-sm-w-#{$i * 1} {
                max-width: #{$i * 1%};
                width: #{$i * 1%};
            }
        }
    }

    @media (min-width: $breakpoint-md) {
        @for $i from 1 through 100 {
            .card-md-w-#{$i * 1} {
                max-width: #{$i * 1%};
                width: #{$i * 1%};
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        @for $i from 1 through 100 {
            .card-lg-w-#{$i * 1} {
                max-width: #{$i * 1%};
                width: #{$i * 1%};
            }
        }
    }
}

.action-card,
.event-admin-card,
.club-card,
.ticket-card,
.order-card,
.course-card,
.combination-card {
    border-radius: 10px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    margin-bottom: 8px;

    .MuiSkeleton-root {
        display: inline-block;
    }

    a,
    a:visited {
        color: $color-heavy-metal;

        &:hover {
            color: $color-heavy-metal;
            text-decoration: none;
        }
    }

    span.label {
        padding: 6px 16px;
    }

    .MuiCardActionArea-root {
        font-family: Lato;
        font-size: 1rem;
    }

    .MuiCardContent-root {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        font-weight: normal;
        justify-content: space-between;
        padding: 24px 32px;

        @media (max-width: $breakpoint-sm) {
            padding: 24px;
        }

        // &:last-child {
        //     padding-bottom: 0;
        // }

        div:not(.MuiAvatar-root) {
            max-width: 25%;
            width: 25%;

            @media (max-width: $breakpoint-md) {
                max-width: 40%;
                width: 40%;
            }

            @media (max-width: $breakpoint-sm) {
                max-width: 100%;
                width: 100%;
            }

            &:first-child {
                max-width: 55%;
                width: 55%;

                @media (max-width: $breakpoint-sm) {
                    max-width: 100%;
                    width: 100%;
                }
            }

            &.actions {
                max-width: 20%;
                text-align: right;
                width: 20%;

                &.isLoading {
                    padding: 0;
                }

                @media (max-width: $breakpoint-md) {
                    max-width: 40%;
                    width: 40%;
                }

                @media (max-width: $breakpoint-sm) {
                    margin-top: 24px;
                    max-width: 100%;
                    text-align: center;
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }

        time {
            color: $color-warm-grey;
            display: block;
            font-size: 0.875rem;
            margin-bottom: 5px;

            @media (min-width: $breakpoint-md) {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 5px;
            }
        }

        small {
            color: $color-warm-grey !important;
            font-weight: normal !important;
            display: block;
            margin-top: 4px;
        }
    }
}

.action-card {
    .MuiCardContent-root {
        div:not(.MuiAvatar-root):first-child,
        div.content,
        div:first-child {
            max-width: 75%;
            width: 75%;
        }

        @media (max-width: 960px) {
            div:not(.MuiAvatar-root).actions {
                max-width: 20%;
                width: 20%;
            }
        }

        @media (max-width: 600px) {
            div:not(.MuiAvatar-root):first-child,
            div.content,
            div:first-child {
                max-width: 100%;
                width: 100%;
            }

            div:not(.MuiAvatar-root).actions {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

.combination-card {
    .MuiCardContent-root {
        position: relative;

        &:not(:first-child) {
            border-top: 1px solid $color-mercury;
            padding-top: 16px;

            .startNumber {
                visibility: hidden;
            }
        }

        div:not(.MuiAvatar-root) {
            max-width: 100%;
            width: auto;

            &:first-child {
                width: auto;
            }

            &.actions,
            &:last-child {
                max-width: 35%;
                width: 35%;

                @media (max-width: 800px) {
                    max-width: 80%;
                    position: absolute;
                    right: 24px;
                    top: 22px;
                    width: 80%;

                    .MuiSvgIcon-root {
                        height: 1.15rem;
                        margin-left: 5px;
                        width: 1.15rem;
                    }
                }

                img.country {
                    border-radius: 3px;
                    display: none;
                    height: 30px;
                    margin-top: 1px;
                    overflow: hidden;
                    vertical-align: middle;

                    @media (max-width: 800px) {
                        display: inline-block;
                        height: 20px;
                    }
                }

                @media (max-width: $breakpoint-sm) {
                    margin-top: 0;
                    text-align: right;

                    button {
                        width: auto;
                    }
                }
            }

            &.startNumber {
                color: rgba($color-black, 0.6);
                font-size: 0.875rem;
                max-width: 50px;
                width: 50px;

                span {
                    display: block;
                }

                @media (max-width: 800px) {
                    display: block;
                    margin-bottom: 16px;
                    max-width: 100%;
                    width: 100%;

                    span {
                        display: inline-block;
                    }
                }
            }

            &.free {
                color: rgba($color-black, 0.6);
                flex-grow: 2;
            }

            &.country {
                max-width: 60px;
                width: 60px;

                @media (max-width: 800px) {
                    display: none;
                }

                img {
                    border-radius: 3px;
                    height: 30px;
                    overflow: hidden;
                    vertical-align: middle;
                }
            }

            &.name {
                flex-grow: 2;
            }

            span.label {
                margin-right: 4px;

                @media (max-width: 800px) {
                    border-radius: 3px;
                    font-size: 0.625rem;
                    line-height: 1;
                    margin-right: 8px;
                    padding: 5px 8px;
                }
            }
        }
    }
}

.course-card {
    @media (max-width: $breakpoint-md) {
        .MuiCardContent-root {
            position: relative;

            &:first-child {
                padding-top: 48px;
            }

            div:not(.MuiAvatar-root) {
                time {
                    height: 20px;
                }

                &.actions {
                    margin-top: 0;
                    position: absolute;
                    right: 24px;
                    text-align: right;
                    top: 22px;

                    @media (max-width: $breakpoint-md) {
                        top: 12px;
                    }

                    @media (max-width: $breakpoint-sm) {
                        small {
                            text-align: center;
                        }
                    }

                    .MuiIconButton-label {
                        justify-content: flex-end;
                    }

                    .MuiSvgIcon-root {
                        height: 1.15rem;
                        margin-left: 5px;
                        width: 1.15rem;
                    }

                    button {
                        width: auto;
                    }
                }
            }
        }
    }

    span.label {
        margin-right: 4px;

        @media (max-width: 800px) {
            border-radius: 3px;
            font-size: 0.625rem;
            line-height: 1;
            padding: 5px 8px;
        }
    }
}

.event-admin-card,
.club-card,
.ticket-card,
.order-card {
    .MuiCardContent-root {
        position: relative;

        @media (max-width: $breakpoint-md) {
            align-items: flex-start;
            flex-direction: column;
        }

        div:not(.MuiAvatar-root) {
            max-width: 100%;
            min-width: 0;
            width: auto;

            &:first-child,
            &:last-child {
                max-width: 100%;
                min-width: 0;
                width: auto;
            }

            &.orderId {
                color: $color-warm-grey;
                max-width: 5%;
                width: 5%;
            }

            &.user {
                align-items: center;
                display: flex;
                max-width: 20%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 15%;

                div {
                    margin-right: 8px;
                }

                @media (max-width: 1200px) and (min-width: 960px) {
                    strong {
                        display: none;
                    }
                }
            }

            &.creationDate {
                color: $color-warm-grey;
                font-size: 0.875rem;
                max-width: 20%;
                width: 20%;
            }

            &.subscriptions {
                max-width: 20%;
                width: 20%;
            }

            &.status {
                max-width: 15%;
                width: 15%;
            }

            &.total {
                max-width: 10%;
                text-align: right;
                width: 10%;
            }

            @media (max-width: $breakpoint-md) {
                &.orderId,
                &.user,
                &.creationDate,
                &.subscriptions,
                &.status,
                &.total {
                    max-width: 100%;
                    width: 100%;
                }

                &.orderId {
                    padding-bottom: 16px;
                }

                &.creationDate {
                    color: $color-mikado;
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 5px;
                }

                &.user {
                    margin-bottom: 16px;

                    div {
                        font-size: 1rem;
                        height: 30px;
                        width: 30px;
                    }
                }

                &.subscriptions {
                    font-size: 0.875rem;
                }

                &.status {
                    padding-top: 16px;
                }

                &.total {
                    position: absolute;
                    right: 32px;
                    top: 60px;
                }

                &.actions {
                    margin-top: 0;
                    max-width: 60%;
                    position: absolute;
                    right: 24px;
                    text-align: right;
                    top: 22px;
                    width: 60%;

                    .MuiIconButton-label {
                        justify-content: flex-end;
                    }

                    .MuiSvgIcon-root {
                        height: 1.15rem;
                        margin-left: 5px;
                        width: 1.15rem;
                    }
                }
            }

            @media (max-width: $breakpoint-sm) {
                &.status {
                    .MuiChip-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.event-admin-card,
.club-card {
    .MuiCardContent-root {
        div:not(.MuiAvatar-root) {
            &.eventId,
            &.clubId {
                max-width: 15%;
                width: 15%;
            }

            &.name {
                max-width: 30%;
                width: 30%;
            }

            &.clubname,
            &.email {
                max-width: 20%;
                width: 20%;

                small {
                    margin-bottom: 4px;
                }
            }

            &.status,
            &.website {
                max-width: 20%;
                width: 20%;
            }

            &.status {
                margin-top: 16px;
                text-transform: capitalize;

                @media (min-width: $breakpoint-md) {
                    margin-top: 0;
                }
            }

            @media (max-width: $breakpoint-md) {
                &.eventId,
                &.clubId,
                &.name,
                &.email,
                &.website,
                &.clubname,
                &.status {
                    max-width: 100%;
                    width: 100%;
                }

                &.clubname {
                    margin-top: 24px;
                }

                &.name {
                    margin-top: 15px;
                }
            }
        }
    }
}

.ticket-card {
    .MuiCardContent-root {
        div:not(.MuiAvatar-root) {
            &.event {
                max-width: 35%;
                width: 35%;
            }

            @media (max-width: $breakpoint-md) {
                &.event {
                    margin-bottom: 5px;
                    max-width: 70%;
                    width: 70%;
                }

                &.creationDate {
                    position: absolute;
                    right: 32px;
                    text-align: right;
                    top: 57px;
                }
            }

            @media (max-width: $breakpoint-sm) {
                &.event {
                    max-width: 100%;
                    width: 100%;
                }

                &.creationDate {
                    margin-top: 10px;
                    position: relative;
                    right: auto;
                    text-align: left;
                    top: auto;
                }
            }
        }
    }
}

div.card {
    box-shadow: 0 0 10px rgba($color-black, 0.1);
    color: $color-mikado;

    @media (max-width: $breakpoint-sm) {
        margin: 0 16px;
        width: calc(100% - 32px);
    }

    header {
        padding: 40px 16px 0;
        text-align: center;

        h3 {
            color: $color-mikado;
            font-family: 'Nunito';
            font-size: 1.75rem;
            font-weight: 800;
            line-height: 1.15;
            margin-bottom: 8px;

            @media (min-width: $breakpoint-sm) {
                font-size: 2.25rem;
            }
        }

        p {
            margin-bottom: 0;
            margin-top: 8px;
        }
    }

    div.introStat {
        // padding: 0 16px;

        .label {
            background: none;
            color: $color-mikado;
            display: block;
            font-size: 0.875rem;
            font-weight: bold;
            margin-bottom: 4px;
            padding: 0;
        }

        .value {
            display: block;
            font-size: 1.125rem;
            font-weight: bold;
        }

        small {
            color: $color-warm-grey;
            display: block;
            min-height: 16px;
        }

        a {
            display: block;
            font-size: 0.875rem;
            margin-top: 24px;
        }
    }

    .card-content {
        // padding: 0 16px;

        // @media (max-width: $breakpoint-sm) {
        //     padding: 0 8px;
        // }

        footer {
            padding: 0 0 32px;
        }
    }

    footer {
        padding: 0 16px 16px;
    }

    div.dashed-line {
        left: -32px;
        margin: 24px 0;
        width: calc(100% + 64px);
    }

    &.legend {
        background: #fff;
        border-radius: 5px;
        padding: 16px 0;

        .text {
            display: inline-block;
            margin-left: 8px;
        }
    }
}

.breadcrumbs {
    line-height: 125%;

    ol li {
        a {
            color: $color-white;
            font-size: 0.75rem;
            font-weight: normal;
            line-height: 125%;

            &:hover {
                color: $color-white;
                text-decoration: underline;
            }
        }

        &.MuiBreadcrumbs-separator {
            color: rgba($color-white, 0.3);
        }
    }
}

.card {
    &.subscription {
        .MuiCardContent-root {
            padding: 0 16px;

            @media (min-width: $breakpoint-sm) {
                padding: 0 32px;
            }
        }
    }
}

.event-admin-card {
    .name,
    .clubname {
        small {
            margin-bottom: 4px;
            margin-top: 0;
        }
    }
}
