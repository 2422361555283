a,
a:visited {
    color: $color-medium-laurel;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: darken($color-medium-laurel, 10%);
        text-decoration: underline;
    }
}
