@import './variables';

.iconBorder {
    background: $color-spring-wood;
    border-radius: 5px;
    color: $color-cigar;
    display: inline-block;
    line-height: 16px;
    padding: 5px 8px;

    &.iconBorderDark {
        background: $color-bone;
        color: $color-mikado;
    }

    svg {
        font-size: 1rem;
    }

    &.iconBorderLarge {
        align-items: center;
        display: inline-flex;
        height: 56px;
        // height: 48px;
        justify-content: center;
        padding: 8px 12px;
        width: 64px;
        // width: 56px;

        svg {
            font-size: 2rem;
            // font-size: 1.75rem;
        }
    }

    &.iconBorderMedium {
        align-items: center;
        display: inline-flex;
        height: 48px;
        justify-content: center;
        padding: 8px 12px;
        width: 48px;

        svg {
            font-size: 1.25rem;
        }
    }
}

ul.payment-icons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    margin: 0 0 16px;
    padding: 6px 0;

    li {
        margin: 0;
        padding: 6px 8px;

        &:first-child {
            padding-left: 0;
        }

        img {
            height: 30px;
            width: 30px;
        }
    }
}
