div.one-column-table,
div.two-column-table {
    table {
        display: block;
        width: 100%;

        tr,
        tbody,
        thead,
        tbody,
        tfoot,
        td,
        th {
            display: block;
        }

        @media (min-width: $breakpoint-sm) {
            display: table;

            tbody {
                display: table-row-group;
            }

            thead {
                display: table-header-group;
            }

            tfoot {
                display: table-footer-group;
            }

            tr {
                display: table-row;
            }

            th,
            td {
                display: table-cell;
            }
        }

        td,
        th {
            padding-left: 24px;
            padding-right: 24px;
            text-align: left;

            @media (min-width: $breakpoint-sm) {
                padding-left: 32px;
                padding-right: 32px;
            }
        }

        th {
            border-bottom: 0;
            padding-bottom: 4px;

            @media (min-width: $breakpoint-sm) {
                border-bottom: 1px solid rgba(224, 224, 224, 1);
                padding-bottom: 16px;
            }
        }

        td {
            padding-top: 4px;

            @media (min-width: $breakpoint-sm) {
                padding-top: 16px;
            }
        }

        td {
            &:first-child {
                padding-left: 32px;
            }

            &:last-child {
                padding-right: 32px;
            }
        }
    }

    &.nowrap {
        table {
            td,
            th {
                white-space: nowrap;
            }
        }
    }
}

div.one-column-table {
    table {

    }
}
