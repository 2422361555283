@import "./variables";

.CookieConsent {
    align-items: center !important;
    flex-wrap: nowrap;
    justify-content: center;
    text-align: center;

    @media (min-width: 600px) {
        text-align: left;
    }

    > div {
        max-width: 100%;
        width: 100%;

        a {
            display: block;
            margin-top: 16px;
        }

        &:nth-child(2) {
            margin: 15px;
            text-align: right;
        }

        @media (min-width: 600px) {
            width: auto;

            a {
                display: inline-block;
                margin-top: 0;
            }
        }

        @media (max-width: 600px) {
            button {
                display: block;
                width: 100%;
            }
        }
    }
}
