nav.main {
    ul {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-left: 5px;

            a,
            button {
                color: $color-mikado;
                font-weight: normal;
            }

            button.MuiButton-containedSecondary {
                font-weight: bold;
                margin-left: 10px;
                padding: 10px 20px;
            }

            .MuiSvgIcon-root {
                height: 1.25rem;
                width: 1.25rem;
            }

            .MuiBadge-badge {
                border: 2px solid $color-spring-wood;
                border-radius: 50%;
                font-size: .75rem;
                font-weight: bold;
                height: 22px;
                min-width: 22px;
            }
        }
    }
}
